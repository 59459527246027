.cls2-1 {
  opacity: .34;
}

.cls2-1, .cls2-2, .cls2-3, .cls2-4, .cls2-5, .cls2-6, .cls2-7, .cls2-8, .cls2-9, .cls2-10, .cls2-11 {
  fill: none;
}

.cls2-3 {
  opacity: .47;
}

.cls2-4 {
  opacity: .55;
}

.cls2-5 {
  clip-path: url(#clippath2-6);
}

.cls2-6 {
  clip-path: url(#clippath2-5);
}

.cls2-7 {
  clip-path: url(#clippath2-3);
}

.cls2-8 {
  clip-path: url(#clippath2-4);
}

.cls2-9 {
  clip-path: url(#clippath2-2);
}

.cls2-10 {
  clip-path: url(#clippath2-1);
}

.cls2-12 {
  fill: #f96277;
}

.cls2-13 {
  fill: #f9caa7;
}

.cls2-14 {
  fill: #697c87;
}

.cls2-15 {
  fill: #677e93;
}

.cls2-16 {
  fill: #6a6a89;
}

.cls2-17 {
  fill: #f5fcfc;
}

.cls2-18 {
  fill: #fff;
}

.cls2-19 {
  fill: #fffbf8;
}

.cls2-20 {
  fill: #fff5cc;
}

.cls2-21 {
  fill: #e5d873;
}

.cls2-22 {
  fill: #98a8b7;
}

.cls2-23 {
  fill: #babaf4;
}

.cls2-24 {
  fill: #8599c6;
}

.cls2-25 {
  fill: #b28c39;
}

.cls2-26 {
  fill: #aea3c6;
}

.cls2-27 {
  fill: #c6d7e5;
}

.cls2-28 {
  fill: #cc926a;
}

.cls2-29 {
  fill: #d3af54;
}

.cls2-30 {
  fill: #abc1d3;
}

.cls2-31 {
  fill: #442e37;
}

.cls2-32 {
  fill: #445866;
}

.cls2-33 {
  fill: #3f3f51;
}

.cls2-34 {
  fill: #6c7e8e;
}

.cls2-35 {
  fill: #9b948f;
}

.cls2-36 {
  fill: #6cbd90;
}

.cls2-37 {
  fill: #56566d;
}

.cls2-38 {
  fill: #2a2635;
}

.cls2-39 {
  fill: #4a3866;
}

.cls2-11 {
  clip-path: url(#clippath2);
}