@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-in {
    animation: fade-in .8s
}

	@keyframes fade-in {
		0% {
			opacity: 0
		}

		100% {
			opacity: 1
		}
	}

.fade-out {
    animation: fade-out .8s
}

	@keyframes fade-out {
		0% {
			opacity: 0
		}

		100% {
			opacity: 1
		}
	}

.spin {
	-webkit-animation: spin .8s linear infinite;
	animation: spin .8s linear infinite;
}

	@-webkit-keyframes spin {
		0% { -webkit-transform: rotate(0deg); }
		100% { -webkit-transform: rotate(360deg); }
	}

	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}

.loading {
	position: relative;
	overflow: hidden;
	border-radius: .4rem; 
	background: #f3f3f3;
}   

	.loading::before {
		content: '';
		display: block;
		position: absolute;
		left: -150px;
		top: 0;
		height: 100%;
		width: 150px;
		background:  linear-gradient(90deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 50%, rgba(240,240,240,1) 100%);
		animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
	}

	@keyframes load {
		from {
			left: -150px;
		}
		to   {
			left: 100%;
		}
	}

.form-control {
	border: 0;
}